import React from 'react'
import AnimateLetters from '../components/AnimateLetters'

function Home(props, ref) {
    const animatedCharClass = "animate-char"
    const introName = "Jeffrey Lustica"
    const nameArr = introName.split("")

    return (
        <div name="home" className='home' ref={ref}>
            <div className="intro container">
                <p className="intro__greeting">Hello, I'm</p>
                <h1 className="intro__name">
                    <AnimateLetters itemClass={animatedCharClass} strArr= {nameArr} />
                </h1>
                <h4 className="intro__job-desc">Front-end Web Developer</h4>
                <button className="intro__dl-btn">Download CV</button>
            </div>
        </div>
    )
}

const forwardedRef = React.forwardRef(Home)

export default forwardedRef
