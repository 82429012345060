import { faHtml5, faCss3Alt, faJs, faPhp,
    faReact, faSass, faBootstrap, faGit, faNodeJs, faFigma } from '@fortawesome/free-brands-svg-icons'

const data = [
        {id: 1, group: 1, brandName: "HTML5", classname:"brand-html", brandIcon: faHtml5, rate: "9" },
        {id: 6, group: 2, brandName: "React", classname:"brand-react", brandIcon: faReact, rate: "7" },
        {id: 2, group: 1, brandName: "CSS3", classname:"brand-css", brandIcon: faCss3Alt, rate: "8" },
        {id: 7, group: 2, brandName: "SASS", classname:"brand-sass", brandIcon: faSass, rate: "8" },
        {id: 3, group: 1, brandName: "JavaScript", classname:"brand-javascript", brandIcon: faJs, rate: "8" },
        {id: 8, group: 2, brandName: "Bootstrap", classname:"brand-bootstrap", brandIcon: faBootstrap, rate: "7" },
        {id: 4, group: 1, brandName: "ECMASCRIPT (ES) 6", classname:"brand-ecmascript", brandIcon: faJs, rate: "8" },
        {id: 9, group: 2, brandName: "Git", classname:"brand-git", brandIcon: faGit, rate: "7" },
        {id: 5, group: 1, brandName: "Php", classname:"brand-php", brandIcon: faPhp, rate: "5" },
        {id: 10, group: 2, brandName: "Node Js", classname:"brand-nodejs", brandIcon: faNodeJs, rate: "7" },
        {id: 11, group: 2, brandName: "Figma", classname:"brand-figma", brandIcon: faFigma, rate: "8" }
    ]
    
export default data