import React, {useState} from 'react'
import { Link } from 'react-scroll'

export default function Navbar({isElVisible}) {
    const [isNavOpen, setIsNavOpen] = useState(false)

    function toggleNav() {
        setIsNavOpen(prevState => !prevState)
    }

    function closeNav() {
        setIsNavOpen(false)
    }

    const menuOpenClass = isNavOpen && "open"
    const inverseClass = !isElVisible && "inverseClass"

    return (
        <div className={`header ${inverseClass}`}>
            <div className="navbar container">
                <div className="logo">
                    jeff
                </div>

                <div className="menu-btn" onClick={toggleNav}>
                    <span className={`menu-btn__burger ${menuOpenClass}`}></span>
                </div>

                <div className={`menu ${menuOpenClass}`}>
                    <ul className="menu__list">
                        <li className="menu__list-item">
                            <Link activeClass="active" to="home" spy={true} smooth={true} offset={-50} duration={500} className="link" onClick={closeNav}>
                                Home
                            </Link>
                        </li>
                        <li className="menu__list-item">
                            <Link activeClass="active" to="about" spy={true} smooth={true} offset={-50} duration={500} className="link" onClick={closeNav}>
                                About
                            </Link>
                        </li>
                        <li className="menu__list-item">
                            <Link activeClass="active" to="skills" spy={true} smooth={true} offset={-50} duration={500} className="link" onClick={closeNav}>
                                Skills
                            </Link>
                        </li>
                        <li className="menu__list-item">
                            <Link activeClass="active" to="timeline" spy={true} smooth={true} offset={-50} duration={500} className="link" onClick={closeNav}>
                                Timeline
                            </Link>
                        </li>
                        <li className="menu__list-item">
                            <Link activeClass="active" to="projects" spy={true} smooth={true} offset={-50} duration={500} className="link" onClick={closeNav}>
                                Projects
                            </Link>
                        </li>
                        <li className="menu__list-item">
                            <Link activeClass="active" to="hobbies" spy={true} smooth={true} offset={-50} duration={500} className="link" onClick={closeNav}>
                                Hobbies
                            </Link>
                        </li>
                        <li className="menu__list-item">
                            <Link activeClass="active" to="contact" spy={true} smooth={true} offset={-50} duration={500} className="link" onClick={closeNav}>
                                Contact
                            </Link>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    )
}
