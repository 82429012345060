import React from "react";
import myPhoto from "../images/homebg.jpg"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {faFacebookF, faLinkedinIn, faGoogle, faGithub} from "@fortawesome/free-brands-svg-icons"
import AnimateLetters from "../components/AnimateLetters";
import useObserver from "../hooks/useObserver";

export default function About() {
    const animatedCharClass = "animate-char"
    const introName = "About me"
    const nameArr = introName.split("")

    const [ref, isElVisible] = useObserver({threshold: .50}, {once : true})
    const animateElClass = isElVisible && "animate-el"

    const socialIcons = [faFacebookF, faLinkedinIn, faGoogle, faGithub]
    const socialIconsEl = socialIcons.map((icon, i) => {
        return (
            <FontAwesomeIcon key={i} icon={icon} className={`icon ${animateElClass}`}/>
        )
    })

    return (
        <div name="about" ref={ref} className="about">
            <div className="container">
                <div className="card">
                    <img className="card__photo" src={myPhoto} alt="my display profile"/>
                    <div className="card__social-icons">
                        {socialIconsEl}
                    </div>
                </div>
                <h1 className="title">
                    {isElVisible && <AnimateLetters itemClass={animatedCharClass} strArr= {nameArr} />}
                </h1>
                <p className={`desc ${animateElClass}`}>Lorem ipsum dolor sit amet consectetur adipisicing elit. Distinctio officiis harum earum nesciunt alias aliquid facere iure voluptatum autem sunt esse minus expedita delectus commodi omnis suscipit quo, modi quia. Lorem ipsum dolor sit amet consectetur adipisicing elit. Distinctio officiis harum earum nesciunt alias aliquid facere iure voluptatum autem.</p>
            </div>
        </div>
    )
}