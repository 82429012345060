import React from "react";
import Navbar from "./sections/Navbar";
import Home from "./sections/Home";
import About from "./sections/About";
import Skills from "./sections/Skills";
import useObserver from "./hooks/useObserver";

function App() {

  const [ref, isElVisible] = useObserver({rootMargin: "-100px"}, {once: false})

  return (
    <div className="App">
      <Navbar isElVisible={isElVisible} />
      <Home ref = {ref}/>
      <About />
      <Skills />
    </div>
  );
}

export default App;
