import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import skillsData from '../utils/skillsData'

export default function AllSkills({activeTab}) {
    const allSkillContentEl = skillsData.map((skill => {
        const {id, group, brandName, classname, brandIcon, rate} = skill

        const activeClass = activeTab === 0 || activeTab === group ? "grow" : "shrink"
        
        return (
            <div className={`item ${activeClass}`} key={id}>
                <h4 className="item__name">{brandName}</h4>
                <FontAwesomeIcon icon={brandIcon} className={`item__brand-icon ${classname}`} />

                <div className="item__rate">
                    <div className="outer">
                        <div className="inner">
                            <div className="number">{rate}</div>
                        </div>
                    </div>

                    <svg xmlns="http://www.w3.org/2000/svg" version="1.1" width="80px" height="80px" className='item__rate-svg'>
                        <defs>
                            <linearGradient >
                            <stop offset="0%" stopColor="#e91e63" />
                            <stop offset="100%" stopColor="#673ab7" />
                            </linearGradient>
                        </defs>
                        <circle cx="40" cy="40" r="35" strokeLinecap="round" />
                    </svg>
                </div>
            </div>
        )
    }))

    // const allSkillContentEl = skillsData[0].allSkills.map((skill => {
    //     const {id, brandName, classname, brandIcon, rate} = skill
    //     return (
    //         <div className="content" key={id}>
    //             <h4 className="content__name">{brandName}</h4>
    //             <FontAwesomeIcon icon={brandIcon} className={`content__brand-icon ${classname}`} />

    //             <div className="content__rate">
    //                 <div className="outer">
    //                     <div className="inner">
    //                         <div className="number">{rate}</div>
    //                     </div>
    //                 </div>

    //                 <svg xmlns="http://www.w3.org/2000/svg" version="1.1" width="80px" height="80px" className='content__rate-svg'>
    //                     <defs>
    //                         <linearGradient >
    //                         <stop offset="0%" stopColor="#e91e63" />
    //                         <stop offset="100%" stopColor="#673ab7" />
    //                         </linearGradient>
    //                     </defs>
    //                     <circle cx="40" cy="40" r="35" strokeLinecap="round" />
    //                 </svg>
    //             </div>
    //         </div>
    //     )
    // }))

    return (
        <>
            {allSkillContentEl}
        </>
    )
}
