import React from "react";

export default function AnimateLetters({itemClass, strArr}) {
    const animatedText = strArr.map((char, i) => {
        return <span key={char + i} className={`${itemClass} _${i}`}>{char}</span>
    })

    return (
        <div>
            {animatedText}
        </div>
    )
}