import React, {useState} from 'react'
import AnimateLetters from '../components/AnimateLetters'
import AllSkills from '../components/AllSkills'

export default function Skills() {
    const [activeTab, setActiveTab] = useState(0)

    const animatedCharClass = "animate-char"
    const introName = "Skills"
    const nameArr = introName.split("")

    const tabsBtnText = ["All","Web Development", "Librabries / Frameworks / Tools"] 

    const tabsBlocEl = tabsBtnText.map((buttonText, i) => {
        const activeBtn = activeTab === i && "active"
        return (
            <button key={i} className={`tabs__bloc-btn ${activeBtn}`} onClick={() => toggleTab(i)}>
                <h4 className='btn-text'>{buttonText}</h4>
            </button>
        )
    })

    function toggleTab(i) {
        setActiveTab(i)
    }

    return (
        <div name="skills" className='skills'>
            <div className="container">
                <h1 className="title">
                    <AnimateLetters itemClass={animatedCharClass} strArr= {nameArr} />
                </h1>

                <div className="tabs">
                    <div className="tabs__bloc">
                        {tabsBlocEl}
                    </div>

                    <div className="tabs__contents">
                        <AllSkills activeTab = {activeTab}/>
                    </div>
                </div>
            </div>
        </div>
    )
}
